// Fonts
@import url('https://fonts.googleapis.com/css?family=Raleway:300, 400, 600');

// Variables
@import 'variables';

// Bootstrap
@import './node_modules/bootstrap-sass/assets/stylesheets/bootstrap';

body {
  background-color: #f6f6f6;
  padding-top: 80px;
  min-height: 1400px;
}

#menu {
  background-color: #0b2545;
  a {
    color: #f19953;
  }
  a.link-white {
    color: #ffffff;
  }
  .nav > li > a:hover,
  .nav > li > a:focus,
  .nav > li > a:active {
    background-color: transparent !important;
  }
  ul.nav li.active {
  }
  .navbar {
    background-color: #0b2545;
    border: 0px;
  }
  .nav .dropdown-menu > li > a {
    color: #0b2545;
  }

  .menu-links {
    margin-bottom: 0px;
    min-height: 30px !important;
    padding: 10px 15px 0px;
    a {
      color: #ffffff;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 11px;
      text-decoration: none;
    }
  }
}
a {
  color: #0b2545;
}

.container-fluid {
  width: 95%;
  max-width: 1920px;
}
.breadcrumb {
  background-color: transparent !important;
  .active {
    color: #f19953;
  }
}
.page-header {
  margin: 10px 0 22px;
}

#categories .panel:hover {
  border: 1px solid #cccccc;
}
.category {
  text-decoration: none;
  .panel-heading {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
  }
}
.sub-categories ul {
  list-style: none;
  li {
    margin-bottom: 5px;
  }
  li a {
    text-transform: uppercase;
    transition: padding linear 0.25s;
    text-decoration: none;
  }
  li a:hover {
    padding-left: 10px;
  }
}

.product-name {
  min-height: 90px;
}
.product-img {
  min-height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: auto;
    width: auto;
    // max-width: 220px;
    max-width: 100%;
    max-height: 220px;
  }
}

.no-border {
  border: none !important;
}

#catalogues_content {
  min-height: 600px;
}

.search-bar {
  .form-control {
    border: 1px solid #0b2545;
    border-right: 0px;
  }
  button {
    background: #fe7f2d;
    color: #0b2545;
    border: 1px solid #0b2545;
  }
}

.autofill-container {
  position: absolute;
  background-color: #fff;
  //padding: 5px;
  overflow: auto;
  top: 36px;
  margin-left: 1px;
  width: 500px;
  border: 1px solid #ccc;
  display: none;
  max-height: 200px;
  ul {
    padding-left: 0px;
  }
  li {
    list-style: none;
    a {
      display: block;
      padding: 5px 10px;
      text-decoration: none;
      &:hover {
        background-color: #f6f6f6;
      }
    }
  }
}

.salon-item {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin: 5px 0 20px 0;
  border: 1px solid #eee;
  padding: 5px;
  background-color: #ffffff;
  .media-heading {
    margin-bottom: 10px;
    display: inline-block;
    font-weight: bold;
  }
  .media-body {
    padding-top: 10px;
  }
  .salon-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
  }
  img {
    width: 150px;
  }
}

.navbar-search {
  .search-input {
    width: 250px !important;
    border-color: #fe7f2d;
  }
  .dropdown-menu a {
    color: #0b2545 !important;
    .caret {
      display: none;
    }
  }
  .btn-search {
    background: #fe7f2d;
    border-color: #fe7f2d;
    color: #ffffff;
  }
}

.affix {
  top: 100px;
  z-index: 9000;
  // width: 100%;
  // left: 0px;
  background-color: #fff;
  border-top: 2px solid #fe7f2d;
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  // -webkit-transition: all 0.2s ease-in;
  // transition: all 0.2s ease-in;
}

.affix h1 {
  font-size: 24px;
}

.filter {
  background: #fff;
  padding: 20px 10px 0px 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  i {
    line-height: 20px;
  }
  .row {
    margin-bottom: 15px;
  }
  i.pull-right {
    padding-left: 20px;
  }
  input::placeholder {
    /* Most modern browsers support this now. */
    opacity: 0.5;
  }
  h3 {
    font-size: 18px;
    margin: 5px 5px 20px 5px;
    text-align: center;
  }
}

.affix .filter {
  padding: initial;
  margin-bottom: 20px;
  border: none;
}

.filter-inputs {
  // overflow: hidden;
  // max-width: 100%;
  // white-space: nowrap;
  .form-group {
    // position: initial;
    // margin-right: 15px;
    width: 100%;
    padding-bottom: 20px;
    input:not([type='checkbox']):not([type='radio']) {
      width: 100%;
    }
    .filter-field {
      width: 100%;
    }
  }
}

.btn-filter {
  // width: 140px;
  height: 38px;
  border-top: 2px solid #fe7f2d;
}
.btn-reset {
  // width: 80px;
  height: 38px;
  border: 1px solid #fe7f2d;
  border-top: 2px solid #fe7f2d;
  background: #fe7f2d;
  color: #ffffff;
  i {
    color: #ffffff;
  }
}

.filter-field {
  height: 36px;
  padding: 6px 12px;
  display: inline-block;
  width: auto;
  vertical-align: middle;
  font-size: 14px;
  line-height: 1.6;
  border: 1px solid #ccd0d2;
  cursor: pointer;
  min-width: 200px;
}
.filter-field-bold {
  font-weight: bold;
}

.filter-values {
  position: absolute;
  min-width: 200px;
  background: #fff;
  z-index: 900000;
  border: 1px solid #dddd;
  padding: 10px;
  margin-top: 5px;
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  .checkbox {
    display: block;
    padding: 5px;
  }
  .radio {
    display: block;
    padding: 5px;
  }
}

.filter-values-list {
  max-height: 250px;
  overflow: auto;
}

.banners {
  margin-bottom: 40px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  img {
    max-width: 100%;
  }
  .carousel-control.left,
  .carousel-control.right {
    background-image: none !important;
    filter: none !important;
  }
}

#categories {
  .select2-selection__rendered {
    line-height: 32px !important;
  }

  .select2-selection {
    height: 34px !important;
  }

  .select2-container--default .select2-selection--single {
    border-radius: 0px;
    //border: 1px solid #0B2545 !important;
  }

  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    height: 34px !important;
  }
}

.w100 {
  width: 100% !important;
}
.w100px {
  width: 100px !important;
}
